import { Component, Mixins, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import {
  RegisterItem,
  RegisterItemDetail,
  RegisterRequest
} from "@/api/user-profile-index/request";
import UserProfileIndexGet from "@/store/user-profile-index/get";
import UserProfileIndexModify from "@/store/user-profile-index/modify";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";

const ENABLE_EXTEND_USER_PROFILE: number = 1;
const DEFAULT_ADDITIONAL_ITEM_NUM: number = 5;
const EXTEND_ADDITIONAL_ITEM_NUM: number = 50;

enum KoumokuType {
  FREE = 1,
  SINGLE,
  MULTI,
  NUMBER,
  DATE
}

type AdditionalItem = {
  id?: number;
  koumokuMei: string;
  requiredFlg: number;
  koumokuTypeIndex: number;
  deleteFlg: number;
  singleOptions: {
    id?: number;
    name: string;
  }[];
  multipleOptions: {
    id?: number;
    name: string;
  }[];
  numberOptions: [{ id?: number; name: string }, { id?: number; name: string }];
  dateId?: number;
  yearFlg: number;
  monthFlg: number;
  dayFlg: number;
  disableFlg: boolean;
};

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    Draggable
  }
})
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザープロフィール項目";
  headingSub = "User profile";
  breadCrumbs = [
    { text: "マスターデータ管理", disabled: true },
    { text: "ユーザープロフィール項目", disabled: true },
    { text: "店舗一覧", disabled: false, to: { name: "user-profile" } },
    { text: "編集", disabled: true }
  ];

  // タブのタイトル
  itemTabs = [
    {
      typeId: KoumokuType.FREE,
      text: "フリーワード"
    },
    {
      typeId: KoumokuType.SINGLE,
      text: "択一選択"
    },
    {
      typeId: KoumokuType.MULTI,
      text: "複数選択"
    },
    {
      typeId: KoumokuType.NUMBER,
      text: "数字"
    },
    {
      typeId: KoumokuType.DATE,
      text: "日付"
    }
  ];

  // ------------
  // 変動値
  // ------------

  // 入力データ
  inputParams: RegisterRequest = {
    id: 0,
    shopId: Number(this.shopId),
    nameDisplayFlg: 1,
    nameKanaDisplayFlg: 1,
    birthdayRequiredFlg: 0,
    birthdayDisplayFlg: 1,
    genderRequiredFlg: 0,
    genderDisplayFlg: 1,
    isPreview: 0,
    items: [] as RegisterItem[]
  };

  additionalItems: AdditionalItem[] = [];

  maxAdditionalItemNum: number = DEFAULT_ADDITIONAL_ITEM_NUM;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * クエリパラメータから店舗名を返却する
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.getUserProfileIndex();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await UserProfileIndexGet.clearResponse();
    await UserProfileIndexModify.clearResponse();
  }

  get isShowAdditionalItemAddButton() {
    return this.additionalItems.length < this.maxAdditionalItemNum && UserProfileIndexGet.isWritable;
  }

  /**
   * 項目追加ボタンが押下された際のコールバック
   */
  addAdditionalItemClickCallback() {
    this.additionalItems.push({
      koumokuMei: "",
      requiredFlg: 0,
      koumokuTypeIndex: KoumokuType.FREE - 1,
      deleteFlg: 1,
      singleOptions: [
        {
          name: ""
        }
      ],
      multipleOptions: [
        {
          name: ""
        }
      ],
      numberOptions: [{ name: "" }, { name: "" }],
      yearFlg: 0,
      monthFlg: 0,
      dayFlg: 0,
      disableFlg: false
    });
  }

  /**
   * 項目削除ボタンが押下された際のコールバック
   *
   * @param index
   */
  removeAdditionalItemClickCallback(index: number) {
    this.additionalItems.splice(index, 1);
  }

  /**
   * 単一選択の追加ボタンを表示するか否か
   *
   * @param parentIndex
   * @param childIndex
   */
  isShowSingleOptionAddButton(parentIndex: number, childIndex: number) {
    const l = this.additionalItems[parentIndex].singleOptions.length;
    //NEW_DEV-665 cyber start
    return l - 1 === childIndex;
  }

  /**
   * 複数選択の追加ボタンを表示するか否か
   *
   * @param parentIndex
   * @param childIndex
   */
  isShowMultipleOptionAddButton(parentIndex: number, childIndex: number) {
    const l = this.additionalItems[parentIndex].multipleOptions.length;
    return l - 1 === childIndex;
    //NEW_DEV-665 cyber end
  }

  /**
   * 単一選択の追加ボタンが押下された際のコールバック
   *
   * @param parentIndex
   */
  addSingleOptionClickCallback(parentIndex: number) {
    if (this.additionalItems[parentIndex]) {
      this.additionalItems[parentIndex].singleOptions.push({
        name: ""
      });
    }
  }

  /**
   * 単一選択の削除ボタンが押下された際のコールバック
   *
   * @param parentIndex
   * @param childIndex
   */
  removeSingleOptionClickCallback(parentIndex: number, childIndex: number) {
    this.additionalItems[parentIndex].singleOptions.splice(childIndex, 1);
    if (this.additionalItems[parentIndex].singleOptions.length <= 0) {
      this.addSingleOptionClickCallback(parentIndex);
    }
  }

  /**
   * 複数選択の追加ボタンが押下された際のコールバック
   *
   * @param parentIndex
   */
  addMultipleOptionClickCallback(parentIndex: number) {
    if (this.additionalItems[parentIndex]) {
      this.additionalItems[parentIndex].multipleOptions.push({
        name: ""
      });
    }
  }

  /**
   * 複数選択の削除ボタンが押下された際のコールバック
   *
   * @param parentIndex
   * @param childIndex
   */
  removeMultipleOptionClickCallback(parentIndex: number, childIndex: number) {
    this.additionalItems[parentIndex].multipleOptions.splice(childIndex, 1);
    if (this.additionalItems[parentIndex].multipleOptions.length <= 0) {
      this.addMultipleOptionClickCallback(parentIndex);
    }
  }

  private async getUserProfileIndex() {
    await UserProfileIndexGet.get({ shopId: Number(this.shopId) });
    if (!UserProfileIndexGet.isSuccess) {
      await Flash.setErrorNow({
        message: UserProfileIndexGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
      return;
    }
    this.syncGetParams();
  }

  private syncGetParams() {
    const result = UserProfileIndexGet.getResult;

    if (result.extendAdditionalUserProfileFlg == ENABLE_EXTEND_USER_PROFILE) {
      this.maxAdditionalItemNum = EXTEND_ADDITIONAL_ITEM_NUM;
    }
    this.inputParams.id = result.shopProfileList.id;
    this.inputParams.nameDisplayFlg = result.shopProfileList.nameDisplayFlg;
    this.inputParams.nameKanaDisplayFlg = result.shopProfileList.nameKanaDisplayFlg;
    this.inputParams.birthdayRequiredFlg = result.shopProfileList.birthdayRequiredFlg;
    this.inputParams.birthdayDisplayFlg = result.shopProfileList.birthdayDisplayFlg;
    this.inputParams.genderRequiredFlg = result.shopProfileList.genderRequiredFlg;
    this.inputParams.genderDisplayFlg = result.shopProfileList.genderDisplayFlg;
    this.additionalItems = result.shopProfileList.items.map(item => {
      const additionalItem: AdditionalItem = {
        id: item.id,
        koumokuMei: item.koumokuMei,
        requiredFlg: item.requiredFlg,
        koumokuTypeIndex: item.koumokuTypeId - 1,
        deleteFlg: item.deleteFlg,
        singleOptions: [
          {
            name: ""
          }
        ],
        multipleOptions: [
          {
            name: ""
          }
        ],
        numberOptions: [{ name: "" }, { name: "" }],
        yearFlg: 0,
        monthFlg: 0,
        dayFlg: 0,
        disableFlg: true
      };
      switch (item.koumokuTypeId) {
        case KoumokuType.FREE:
          break;
        case KoumokuType.SINGLE:
          if (item.details) {
            additionalItem.singleOptions = item.details.map(detail => {
              return { id: detail.id!!, name: detail.koumokuMei!! };
            });
          }
          break;
        case KoumokuType.MULTI:
          if (item.details) {
            additionalItem.multipleOptions = item.details.map(detail => {
              return { id: detail.id!!, name: detail.koumokuMei!! };
            });
          }
          break;
        case KoumokuType.NUMBER:
          if (item.details && item.details.length === 2) {
            additionalItem.numberOptions[0].id = item.details[0].id;
            additionalItem.numberOptions[0].name = item.details[0].prefixSuffix;
            additionalItem.numberOptions[1].id = item.details[1].id;
            additionalItem.numberOptions[1].name = item.details[1].prefixSuffix;
          }
          break;
        case KoumokuType.DATE:
          if (item.details && item.details.length === 1) {
            additionalItem.dateId = item.details[0].id;
            additionalItem.yearFlg = item.details[0].yearFlg!!;
            additionalItem.monthFlg = item.details[0].monthFlg!!;
            additionalItem.dayFlg = item.details[0].dayFlg!!;
          }
          break;
      }
      return additionalItem;
    });
  }

  /**
   * 登録処理
   */
  async register() {
    await this.callRegisterApi(0);
    if (UserProfileIndexModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "ユーザープロフィールを保存しました。",
        "/user-profile"
      );
    }
  }

  async preview() {
    await this.callRegisterApi(1);
    if (UserProfileIndexModify.isSuccess) {
      WindowOpen.preview(UserProfileIndexModify.getPreviewUrl);
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await UserProfileIndexModify.register(
      this.createRegisterRequest(isPreview)
    );
    if (!UserProfileIndexModify.isSuccess) {
      await Flash.setErrorNow({
        message: UserProfileIndexModify.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest(isPreview: number) {
    const request = { ...this.inputParams };
    request.isPreview = isPreview;
    request.items = this.additionalItems.map(item => {
      const registerItem = {
        id: item.id,
        koumokuMei: item.koumokuMei,
        requiredFlg: item.requiredFlg,
        koumokuTypeId: item.koumokuTypeIndex + 1
      } as RegisterItem;

      switch (registerItem.koumokuTypeId) {
        case KoumokuType.FREE:
          break;
        case KoumokuType.SINGLE:
          registerItem.details = item.singleOptions.map(option => {
            return {
              id: option.id,
              koumokuMei: option.name
            } as RegisterItemDetail;
          });
          break;
        case KoumokuType.MULTI:
          registerItem.details = item.multipleOptions.map(option => {
            return {
              id: option.id,
              koumokuMei: option.name
            } as RegisterItemDetail;
          });
          break;
        case KoumokuType.NUMBER:
          registerItem.details = item.numberOptions.map(option => {
            return {
              id: option.id,
              prefixSuffix: option.name
            } as RegisterItemDetail;
          });
          break;
        case KoumokuType.DATE:
          registerItem.details = [
            {
              id: item.dateId,
              yearFlg: item.yearFlg,
              monthFlg: item.monthFlg,
              dayFlg: item.dayFlg
            }
          ];
          break;
      }
      return registerItem;
    });
    return request;
  }
}
