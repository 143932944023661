import service from "@/api/service";
import { RegisterRequest, GetRequest } from "@/api/user-profile-index/request";
import {
  RegisterResponse,
  GetResponse
} from "@/api/user-profile-index/response";

/**
 * ユーザープロフィール項目情報詳細取得APIをコールします。
 *
 * @param getRequest
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/search-user-profile-index", getRequest);
  return response.data as GetResponse;
}

/**
 * ユーザープロフィール項目情報登録更新APIをコールします。
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-user-profile-index",
    registerRequest
  );
  return response.data as RegisterResponse;
}
