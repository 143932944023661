import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetRequest } from "@/api/user-profile-index/request";
import { GetResponse } from "@/api/user-profile-index/response";
import * as UserProfileIndexAPI from "@/api/user-profile-index";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "user-profile-index/get";

/**
 * ユーザープロフィール項目情報詳細取得APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get isWritable() {
    if (this.getResponse.results) {
      return this.getResponse.results.shopProfileList.writeFlg === 1;
    } else {
      return false;
    }
  }

  get getResult() {
    return this.getResponse.results;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await UserProfileIndexAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
