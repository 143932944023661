import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { isSuccess } from "@/api/response";
import { RegisterRequest } from "@/api/user-profile-index/request";
import { RegisterResponse } from "@/api/user-profile-index/response";
import * as UserProfileIndexAPI from "@/api/user-profile-index";

const MODULE_NAME = "user-profile-index/modify";

/**
 * ユーザープロフィール項目情報登録更新APIを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  registerResponse: RegisterResponse = {} as RegisterResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  get getPreviewUrl() {
    if (this.registerResponse.results) {
      return this.registerResponse.results.previewUrl;
    } else {
      return "";
    }
  }

  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const registerResponse = await UserProfileIndexAPI.register(
      registerRequest
    );
    return {
      registerResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Modify);
